import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventService } from '@fi/app/core/services/event.service';
import { ICreatePersonalEvent } from '@fi/app/core';

@Component({
  selector: 'renovars-delete-personal-modal',
  templateUrl: './delete-personal-modal.component.html',
  styles: [],
})
export class DeletePersonalModalComponent implements OnInit {
  event: ICreatePersonalEvent;

  constructor(
    private eventsService: EventService,
    public dialogRef: MatDialogRef<DeletePersonalModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { event: ICreatePersonalEvent }
  ) {}

  ngOnInit(): void {
    this.event = this.data.event;
  }

  delete() {
    this.eventsService.delete(this.event._id).subscribe((_) => {
      this.dialogRef.close(true);
    });
  }
}
