import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AppointmentSearchDto,
  AvailabilitySlotEntity,
  ContactCompanyEntity,
  ContactEntity,
  ContactIndividualEntity,
  EventEntity,
  EventSearchResponseDto,
  UserMetaEntity,
  UserMetaService,
} from '@fi/app/client';
import { CommonRoles, EventType, FiRoles, getUpline, IDomainValue, IJwtPayload, IPaginateRequest } from '@fi/app/core';
import { AuthServices } from '@fi/app/core/services/auth.services';
import { CalendarioService } from '@fi/app/core/services/calendario.service';
import { ContactsSharedServices } from '@fi/app/core/services/contacts.services';
import { DomainValuesSharedService } from '@fi/app/core/services/domain-values.shared.service';
import { UsersService } from '@fi/app/core/services/users.service';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { CALENDARIO_CONFIG_PROVIDER, CalendarioModuleConfig } from '../../../module.options';
import { DeletePersonalModalComponent } from '../../modals/delete-personal-modal/delete-personal-modal.component';
import { PersonalEventModalComponent } from '../../modals/personal-event-modal/personal-event-modal.component';

@Component({
  selector: 'calendario',
  templateUrl: `./calendario.html`,
})
export class CalendarioComponent implements OnInit {
  list: EventSearchResponseDto[];
  selectedUserAvailability: AvailabilitySlotEntity[];
  storeManagers$: Observable<UserMetaEntity[]>;
  contacts$: Observable<ContactEntity[]>;
  paginateRequest: IPaginateRequest<AppointmentSearchDto> = {
    form: { showOnlyValidEvents: true, canSyncGraph: true },
    skip: 0,
    limit: 1000,
  };
  selected;
  eventTypes: IDomainValue<unknown>[];
  domainValuesList$: Observable<IDomainValue<unknown>[]>;
  currentUser: IJwtPayload;
  agentList$: Observable<UserMetaEntity[]>;
  networkList$: Observable<UserMetaEntity[]>;
  constructor(
    @Inject(CALENDARIO_CONFIG_PROVIDER)
    protected config: CalendarioModuleConfig,
    protected calendarioService: CalendarioService,
    protected usersService: UsersService,
    protected contactsService: ContactsSharedServices,
    protected authService: AuthServices,
    protected dialog: MatDialog,
    protected domainValueService: DomainValuesSharedService,
    protected userMetaService: UserMetaService,
  ) {}

  ngOnInit(): void {
    this.storeManagers$ = this.usersService.getUsersListDownline(
      [FiRoles.IS_ADMIN, FiRoles.IS_BACK_OFFICE, FiRoles.IS_PROPERTY_FINDER],
      null,
    );

    this.domainValueService.list().subscribe((res) => {
      if (res) {
        this.eventTypes = res.filter((d: IDomainValue<unknown>) => d.type === 'event_type');
      }
    });

    this.authService.user().subscribe((u) => (this.currentUser = u));

    this.loadAgentList(null);

    this.networkList$ = this.usersService.getUsersListDownline(
      [FiRoles.IS_ADMIN],
      getUpline(FiRoles.IS_AGENTE_SENIOR),
      null,
      true,
    );
  }

  searchContact(searchString) {
    this.contacts$ = this.contactsService.searchByNominative(searchString).pipe(pluck('data'));
  }

  search() {
    this.calendarioService.search(cloneDeep(this.paginateRequest)).subscribe((res) => (this.list = res.data));
  }

  searchByAddress(event) {
    this.paginateRequest.form.address = event.target.value;
    this.search();
  }

  searchByUser(userId: string) {
    this.selectedUserAvailability = null;
    if(userId) {
      this.userMetaService.findByFusionUserId(userId).subscribe((res) => {
        this.selectedUserAvailability = res ? res.availabilitySlots : null;
      });
    }
    this.paginateRequest.form.network = undefined;
    this.paginateRequest.form.userId = userId;
    this.search();
  }

  viewChange(data) {
    this.paginateRequest.form.eventStartRange = {
      start: data.currentStart,
      end: data.currentEnd,
    };
    this.search();
  }

  onEventClick(event) {
    if (event.event && Object.keys(event.event.extendedProps).length > 0) {
      const isSameUser = this.currentUser.sub === event.event.extendedProps.insertUser;
      const isAdmin = this.currentUser.roles.includes(CommonRoles.IS_ADMIN);
      const isPersonal = event.event.extendedProps.type === EventType.PERSONALE;
      if (isPersonal) {
        if (isSameUser || isAdmin) {
          this.dialog
            .open(DeletePersonalModalComponent, {
              data: {
                event: event.event.extendedProps,
              },
            })
            .afterClosed()
            .subscribe((res) => {
              if (res) {
                this.search();
              }
            });
        }
      } else {
        this.dialog.open(this.config.eventModalClass, {
          minWidth: 800,
          data: {
            event: event.event.extendedProps,
          },
        });
      }
    }
  }

  getColor(event: EventEntity) {
    switch (event.type) {
      case 1:
        return '#29b6f6';
      case 2:
        return '#66bb6a';
      case EventType.PERSONALE:
        return '#FF5252';
      default:
        return '#ffa726';
    }
  }

  createPersonalEventClick() {
    this.dialog
      .open(PersonalEventModalComponent, {
        data: {},
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.search();
        }
      });
  }

  getEvent(e: EventSearchResponseDto) {
    let title = '';
    if (e?.userObj) {
      title = (<ContactCompanyEntity>e.contactObj)?.businessName
        ? e?.userObj.userObj.firstName +
          ' ' +
          e?.userObj.userObj.lastName +
          ' | ' +
          (<ContactCompanyEntity>e.contactObj)?.businessName
        : e?.userObj.userObj.firstName + ' ' + e?.userObj.userObj.lastName;
      title += ' | ';
    }

    if (
      e.contactObj &&
      (<ContactIndividualEntity>e.contactObj)?.firstName &&
      (<ContactIndividualEntity>e.contactObj)?.lastName
    ) {
      title +=
        (<ContactIndividualEntity>e.contactObj)?.firstName + ' ' + (<ContactIndividualEntity>e.contactObj)?.lastName;
    }

    if (e.type === EventType.PERSONALE) {
      if (e.syncedFromOutlookAt) {
        title = `[O] ${e.note}: ${e?.userObj?.userObj.firstName} ${e?.userObj?.userObj.lastName}`;
      } else {
        title = `Riservato da ${e?.userObj?.userObj.firstName} ${e?.userObj?.userObj.lastName}`;
      }
    }

    return {
      title,
      start: <any>e.start,
      end: <any>e.end,
      color: this.getColor(e),
      extendedProps: e,
    };
  }

  onDateClick(event) {
    this.dialog
      .open(PersonalEventModalComponent, {
        data: {
          start: event.date,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.search();
        }
      });
  }

  loadAgentList(rootAgent) {
    this.agentList$ = this.usersService.getUsersListDownline([FiRoles.IS_ADMIN], null, rootAgent);
  }

  setNetworkFilter(value: string) {
    this.paginateRequest.form.userId = undefined;
    this.paginateRequest.form.network = value;
    this.search();
  }
}
