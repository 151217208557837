  <fullstack-header></fullstack-header>

  <ng-template header-body>
    <h1 class="tx-title-1 m-2">Calendario</h1>
  </ng-template>
  <div class="p-2">

  <div class="mt-6 grid grid-cols-4 gap-y-6 gap-x-4">
    <p-floatLabel>
      <p-dropdown
        [pTooltip]="'Puoi filtrare gli eventi in base ad un singolo utente oppure per la rete specificata'"
        [options]="storeManagers$ | async"
        [filter]="true"
        [ngModel]="paginateRequest.form.userId"
        (onChange)="searchByUser($event.value)"
        [filterFields]="['firstName', 'lastName']"
        optionValue="_id"
        [showClear]="true"
        inputId="user"
        styleClass="w-full"
        placeholder="Filtro Utente"
        filterPlaceholder="Nome o cognome utente"
      >
        <ng-template let-user pTemplate="selectedItem">
          <div class="flex align-items-center gap-2">
            <div *ngIf="user">{{ user?.userObj.firstName }} {{ user?.userObj.lastName }}</div>
            <div *ngIf="!user">Filtro Utente</div>
          </div>
        </ng-template>

        <ng-template let-user pTemplate="item">
          <div class="flex align-items-center gap-2 succ">
            <div>{{ user?.userObj.firstName }} {{ user?.userObj.lastName }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <label for="user">Utente</label>
    </p-floatLabel>

    <p-floatLabel>
      <p-dropdown
        [pTooltip]="'Puoi filtrare gli eventi in base ad un singolo utente oppure per la rete specificata'"
        [options]="storeManagers$ | async"
        [filter]="true"
        [ngModel]="paginateRequest.form.userId"
        (onChange)="setNetworkFilter($event.value); loadAgentList($event.value)"
        [filterFields]="['firstName', 'lastName']"
        optionValue="_id"
        [showClear]="true"
        inputId="network"
        styleClass="w-full"
        placeholder="Filtro Network"
        filterPlaceholder="Nome o cognome Network"
      >
        <ng-template let-user pTemplate="selectedItem">
          <div class="flex align-items-center gap-2">
            <div *ngIf="user">{{ user?.userObj.firstName }} {{ user?.userObj.lastName }}</div>
            <div *ngIf="!user">Filtro Network</div>
          </div>
        </ng-template>

        <ng-template let-user pTemplate="item">
          <div class="flex align-items-center gap-2 succ">
            <div>{{ user?.userObj.firstName }} {{ user?.userObj.lastName }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <label for="network">Network</label>
    </p-floatLabel>

    <p-floatLabel>
      <p-autoComplete
        [pTooltip]="'Digita il nominativo del contatto'"
        (onSelect)="paginateRequest.form.contactId = $event.value._id; search()"
        (onClear)="paginateRequest.form.contactId = null; search()"
        (completeMethod)="searchContact($event.query)"
        [suggestions]="(contacts$  | async) || [] "
        [showClear]="true"
        inputId="contact"
        styleClass="w-full"
        optionLabel="firstName"
        inputStyleClass="w-full"
        optionValue="_id"
        placeholder="Filtro Contatti"
        filterPlaceholder="Nome o cognome Contatto"
      >
      <ng-template let-contact pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>{{ contact | contactName}}</div>
        </div>
      </ng-template>



      </p-autoComplete>
      <label for="contact">Contatto</label>
    </p-floatLabel>

    <app-input-address [options]="{ label: 'Indirizzo' }" (keyup.enter)="searchByAddress($event)"> </app-input-address>


    <p-floatLabel>
      <p-dropdown
        [options]="eventTypes"
        ngModel
        [showClear]="true"
        (ngModelChange)="paginateRequest.form.eventType = $event; search()"
        optionValue="code"
        styleClass="w-full"
        placeholder="Tipo evento"
        inputId="eventType"
      >
      </p-dropdown>
      <label for="eventType">Tipo evento</label>
    </p-floatLabel>


    <button class="col-start-4" mat-raised-button color="primary" (click)="createPersonalEventClick()">
      <mat-icon>add</mat-icon>  Evento personale
    </button>


  </div>

</div>

<fullstack-fullcalendar
  (viewChange)="viewChange($event)"
  (eventClick)="onEventClick($event)"
  (dateClick)="onDateClick($event)"
>
  <div *ngFor="let e of list" f-event [event]="getEvent(e)"></div>
  <div
    *ngFor="let a of selectedUserAvailability"
    f-event
    [event]="{ title : 'Slot disponibile',
    daysOfWeek : [a.day],
    startTime : a.start.toString().padStart(2, '0') + ':00:00',
    endTime : a.end.toString().padStart(2, '0') + ':00:00',
    color : 'orange'} "
  ></div>
</fullstack-fullcalendar>
