import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { PipesModule } from '@fi/app/core/pipes/pipes.module';
import { UiModule } from '@fi/app/ui/ui.module';
import { TooltipModule } from 'primeng/tooltip';
import { DeletePersonalModalComponent } from './components/modals/delete-personal-modal/delete-personal-modal.component';
import { PersonalEventModalComponent } from './components/modals/personal-event-modal/personal-event-modal.component';
import { CalendarioComponent } from './components/pages/list/calendario.component';
import { CALENDARIO_CONFIG_PROVIDER, CalendarioModuleConfig } from './module.options';

const ROUTES: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: CalendarioComponent,
      },
    ],
  },
];
@NgModule({
  declarations: [CalendarioComponent, PersonalEventModalComponent, DeletePersonalModalComponent],
  imports: [
    UiModule,
    PipesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(ROUTES),
    TooltipModule,
  ],
  exports: [UiModule, PipesModule],
})
export class CalendarioModule {
  static forRoot(config: CalendarioModuleConfig): ModuleWithProviders<CalendarioModule> {
    return {
      ngModule: CalendarioModule,
      providers: [
        {
          provide: CALENDARIO_CONFIG_PROVIDER,
          useValue: config,
        },
      ],
    };
  }
}
