<h2 mat-dialog-title>Annulla evento personale</h2>
<mat-dialog-content class="mat-typography">

  <div class="grid grid-cols-2 gap-2">
    <p class="font-semibold">Utente:</p>
    <p>{{ event.contactObj | contactName }}</p>

    <p class="font-semibold">Inizio:</p>
    <p>{{ event.start | date: 'short' }}</p>

    <p class="font-semibold">Fine:</p>
    <p>{{ event.end | date: 'short' }}</p>

    <p class="font-semibold">Note:</p>
    <p>{{ event.note }}</p>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button class="me-2" mat-dialog-close>Annulla</button>
  <button mat-raised-button color="primary" cdkFocusInitial (click)="delete()">Elimina Evento</button>
</mat-dialog-actions>
