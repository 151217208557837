import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventService } from '@fi/app/core/services/event.service';
import { ICreatePersonalEvent } from '@fi/app/core';
import dayjs from 'dayjs';

@Component({
  selector: 'renovars-personal-event-modal',
  templateUrl: './personal-event-modal.component.html',
  styleUrls: ['./personal-event-modal.component.scss'],
})
export class PersonalEventModalComponent implements OnInit {
  MIN_HOUR = 7;
  MAX_HOUR = 20;
  @Input() options: { maxHour: number; minHour: number } = { maxHour: this.MAX_HOUR, minHour: this.MIN_HOUR };

  slot: { endHours: number[]; start: number; startHours: number[]; end: number; day?: number };
  startHours = [];
  endHours = [];
  date: Date = dayjs().toDate();
  note = 'Evento personale';

  event: ICreatePersonalEvent;

  constructor(
    private eventsService: EventService,
    public dialogRef: MatDialogRef<PersonalEventModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { start: Date }

  ) {}

  ngOnInit(): void {

    this.startHours = Array(this.options.maxHour - this.options.minHour)
      .fill(0)
      .map((_, i) => i + this.options.minHour);
    this.endHours = Array(this.options.maxHour - this.options.minHour + 1)
      .fill(0)
      .map((_, i) => i + this.options.minHour);
    if (this.data.start) {
      this.date = this.data.start;
      const startHour = dayjs(this.date).hour();
      if (startHour <= this.MAX_HOUR && startHour >= this.MIN_HOUR) {
        this.initSlot(startHour, startHour + 1)
      } else {
        this.initSlot(this.options.minHour, this.options.maxHour)
      }
    } else {
      this.initSlot(this.options.minHour, this.options.maxHour)

    }

  }

  initSlot(startHour: number, endHour: number) {
    this.slot = {
      day: 1,
      start: startHour,
      end: endHour,
      endHours: this.filterEndHours(startHour),
      startHours: this.filterStartHours(endHour),
    };
  }

  checkStartRange() {
    this.slot.endHours = this.filterEndHours(this.slot.start);
  }

  checkEndRange() {
    this.slot.startHours = this.filterStartHours(this.slot.end);
  }

  filterStartHours(endHour): number[] {
    return this.startHours.filter((hour) => hour < endHour);
  }

  filterEndHours(startHour): number[] {
    return this.endHours.filter((hour) => hour > startHour);
  }

  save() {
    this.event = {
      note: this.note,
      start: dayjs(this.date).hour(this.slot.start).minute(0).second(0).toDate(),
      end: dayjs(this.date).hour(this.slot.end).minute(0).second(0).toDate(),
    };
    this.eventsService.createPersonalEvent(this.event).subscribe((_) => {
      this.dialogRef.close(true);
    });
  }
}
